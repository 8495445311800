import UserStats from '@components/userstats'
import LearningCatalog from '@components/learning-catalog/main'
import { Modes } from '@components/learning-catalog/types'

import { render } from 'inferno'
import Delegate from 'ftdomdelegate'

import { forEach } from '@misc/utilities'

declare var M:any

export function bootstrap(delegate:Delegate) {
  bootstrapLearningCatalog()
  bootstrapLearningSphereLinks(delegate)
  bootstrapUserStats()
}

const bootstrapLearningCatalog = () => {

  const componentIds:Modes[] = [
    'learning-catalog',
    'mylearningsphere',
    'learningsphere_recommended',
    'learningsphere_current'
  ]

  componentIds.forEach((id: Modes) => {
    const containers = document.querySelectorAll(`[data-component=${id}]`)

    if (containers) {
      forEach(containers, container => {
        const fetchUrl = container.getAttribute('data-fetchurl')
        render(<LearningCatalog mode={id} fetchUrl={fetchUrl} />, container)
      })
    }
  })
}

const bootstrapLearningSphereLinks = (delegate:Delegate) => {
  delegate.on('click', '[data-action="show-learning-sphere"]', e => {
    e.preventDefault()
    const linkElem = e.target
    const courseId = linkElem.getAttribute('data-id')
    const url = `/local/lernprofil/ajax.php?action=set_learningspheres&id=${courseId}`
    const successCb = () => {
      window.location.href = '/'
    }
    return clickHandlerAjax(url, successCb)
  })
}

const clickHandlerAjax = (url, successCb) => {

  fetch(`${url}&sesskey=${M.cfg.sesskey}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin'
  })
  .then(response => response.json())
  .then(json => {
    console.log(json)
    if(successCb) { successCb() }
  })
}

const bootstrapUserStats = () => {
  const userstatsContainer = document.getElementById('report-userstats-accesstable')
  if(!userstatsContainer) { return }

  const props = {
    stats: [ 0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0 ]
  }

	render(
    <UserStats {...props} />,
		userstatsContainer
	)
}
