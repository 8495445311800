import { linkEvent, Component, SemiSyntheticEvent } from 'inferno'
import intersection from 'lodash/intersection'
import classNames from 'classnames'
import { Props, State, JsonData, LearningSphereCategory, Category, Action, User } from './types'
import { renderModal } from '@bootstrap/modals'
import { LdsLoader } from '@components/loaders'
import intersperse from 'intersperse'
import queryString from 'query-string'
import debounce from 'debounce'

declare var M:any

const LearningCatalogModes = [
  'learning-catalog',
  'mylearningsphere',
  'learningsphere_recommended',
  'learningsphere_current'
]

class LearningCatalog extends Component<Props, State> {

  public learningSphereHashRegExp = new RegExp(`^(?<mode>${LearningCatalogModes.join('|')})-lernraum-(?<id>\\d+)`)
  public endDateThreshold: number = 3600 * 24 * 14 * 1000 // 2 weeks
  public now: number

  public reloadToken: Element
  public intersectionObserver: IntersectionObserver

  public modesWithToolbar = [ 'learning-catalog', 'mylearningsphere' ]
  public modesWithFilter = [ 'learning-catalog', 'mylearningsphere' ]
  public modesWithExtendedFilter = [ 'learning-catalog' ]

  public manualReloadSteps = 50

  private abortController: AbortController|null
  private abortSignal: AbortSignal|null

  private delayedForceReload: () => void

  public state: State = {
    categories: [],
    courses: [],
    isLoading: false,
    filterQuery: null,
    sortBy: 'name',
    sortControls: {
      name: {
        label: M.str.theme_project.name_sort,
        direction: 'asc'
      }
    },
    itemsPerPage: 10,
    currentPage: 0,
    learningSphereCategories: [],
    extendedFilterOpen: false,
    activeExtendedFilters: [],
    activeTimeFilter: 'current',
    reloadTokenVisible: false,
    endOfData: false
  }

  constructor(props) {
    super(props)

    this.initNow()

    this.sortByName = this.sortByName.bind(this)

    this.delayedForceReload = debounce(this.forceReload, 200)

    this.handleHashChange = this.handleHashChange.bind(this)
    this.handleClickNext = this.handleClickNext.bind(this)
    this.handleClickPrevious = this.handleClickPrevious.bind(this)
    this.handleClickPage = this.handleClickPage.bind(this)
    this.handleClickPage = this.handleClickPage.bind(this)
    this.handleClickReloadHandler = this.handleClickReloadHandler.bind(this)

    this.onAfterInitialLoad = this.onAfterInitialLoad.bind(this)
  }

  componentWillUpdate() {
    this.initNow()
  }

  componentDidMount() {
    this.loadData()
    this.observeReloadToken()
  }

  forceReload() {
    console.log('forceReload')
    this.loadData(0, true)
  }

  isManualReloadPage() {
    const { currentPage } = this.state
    return currentPage > 1 && currentPage % this.manualReloadSteps === 0
  }

  requestAutoReload(forceReload = false) {
    const { currentPage, reloadTokenVisible } = this.state

    if (forceReload || (reloadTokenVisible && !this.isManualReloadPage())) {
      this.loadData(currentPage + 1)
    }
  }

  observeReloadToken() {
    if (this.reloadToken) {
      this.intersectionObserver = new IntersectionObserver((entries) => {
        const { isLoading } = this.state
        entries.forEach(entry => {
          if (entry.target === this.reloadToken) {

            console.debug('Token visibility changed to', entry.isIntersecting)

            this.setState({
              reloadTokenVisible: entry.isIntersecting
            })

            if (!isLoading) {
              this.requestAutoReload()
            }
          }
        })
      })
      this.intersectionObserver.observe(this.reloadToken)
    }
    else {
      console.warn('[learning-catalog] Automatic reload disabled, token element not visible!')
    }
  }

  onAfterInitialLoad() {
    console.debug('[learning-catalog] data loaded')

    this.initNow()
    this.initRouting()
  }

  initRouting() {
    console.debug('[learning-catalog] initialize router')

    window.addEventListener( 'hashchange', this.handleHashChange)

    this.handleHashChange()
  }

  get locationHash() {
    return window.location.hash ? window.location.hash.substr(1) : null
  }

  handleHashChange() {

    console.debug('[learning-catalog] handle default route')

    const hash = window.location.hash ? window.location.hash.substr(1) : null

    if (!hash) {
      return
    }

    const routeMatch = this.learningSphereHashRegExp.exec(hash)

    if (!routeMatch || !(routeMatch as any).groups) {
      return
    }

    if (this.props.mode !== (routeMatch as any).groups.mode) {
      return
    }

    const routeId = (routeMatch as any).groups.id

    if (routeId) {
      const category = this.state.categories.find((category: Category) => {
        return category.ID == routeId
      })

      if (category) {
        console.debug('[learning-catalog] category', category)
        this.renderInfoModal(category)
      }
      else {
        console.error(`[learning-catalog] no category found (id: ${routeId})`)
      }
    }
    else {
      console.error('[learning-catalog] invalid parameter', hash)
    }
  }

  initNow() {
    this.now = Date.now()
  }

  async loadData(page = 0, force = false) {

    if (this.state.endOfData && !force) {
      return
    }

    if (this.abortController) {
      this.abortController.abort()
      this.abortController = null
      this.abortSignal = null
    }

    this.abortController = new AbortController()
    this.abortSignal = this.abortController.signal

    console.debug(`[LearningCatalog] Loading data for page ${page}`)
    this.setState({ isLoading: true })

    const { filterQuery, activeExtendedFilters, activeTimeFilter, sortControls } = this.state
    const sortKeys = sortControls ? Object.keys(sortControls) : null

    const filterData = {
      query: filterQuery ? filterQuery : undefined,
      time: activeTimeFilter ? activeTimeFilter : undefined,
      extended: activeExtendedFilters.length ? activeExtendedFilters : undefined,

      // Only one sort column for now
      sortCol: sortKeys && sortKeys.length ? sortKeys[0] : null,
      sortDirection: sortKeys && sortKeys.length ? sortControls[sortKeys[0]].direction : 'asc'
    }

    const response = await fetch(this.props.fetchUrl + '&sesskey=' + M.cfg.sesskey, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        page: page,
        filters: filterData
      }),
      signal: this.abortSignal
    }).catch(e => console.error(e))

    if (!response) {
      console.warn('Request failed.')
      return
    }

    this.abortController = null
    this.abortSignal = null

    const json: JsonData = await response.json()

    const stateChanges: Partial<State> = {
      currentPage: page,
      isLoading: false,
      endOfData: false,
    }

    const { categories, courses } = this.state

    if (json) {
      if (json.EndOfData) {
        stateChanges.endOfData = true
      }
      if (Array.isArray(json.LearningSpheres)) {
        stateChanges.categories = page ? [ ...categories, ...json.LearningSpheres ] : [ ...json.LearningSpheres ]
      }
      if (Array.isArray(json.Courses)) {
        stateChanges.courses = page ? [ ...courses, ...json.Courses ] : [ ...json.Courses ]
      }
      if (json.ItemsPerPage) {
        stateChanges.itemsPerPage = json.ItemsPerPage
      }

      if (json.LearningSphereCategories) {
        stateChanges.learningSphereCategories = Object.keys(json.LearningSphereCategories).reduce(
          (learningSphereCategories: LearningSphereCategory[], key: string, index: number): LearningSphereCategory[] => {
            if (json.LearningSphereCategories) {
              learningSphereCategories.push({ ID: parseInt(key, 10), Name: json.LearningSphereCategories[key] })
            }
            return learningSphereCategories
        }, [])
      }

      this.setState(stateChanges)

      if (page === 0) {
        setTimeout(this.onAfterInitialLoad, 20)
      }

      this.requestAutoReload()
    }
  }

  handleClickShow(category: Category, e: Event) {
    e.preventDefault()
    this.setLearningSphere(parseInt(category.ID, 10))
  }

  setLearningSphere(id: number) {
    fetch('/local/lernprofil/ajax.php?action=set_learningspheres&id='+id+'&sesskey='+M.cfg.sesskey, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(() => window.location.href = `${M.cfg.wwwroot}/Lernraum`)
  }

  handleFilterQuery(self: LearningCatalog, e: SemiSyntheticEvent<HTMLInputElement>) {
    e.preventDefault()
    const element = e.target as HTMLInputElement
    self.setState({
      filterQuery: element.value.toLowerCase(),
      currentPage: 0
    })

    self.delayedForceReload()
  }

  handleFilterSubmit(self: LearningCatalog, e: SemiSyntheticEvent<HTMLFormElement>) {
    e.preventDefault()
  }

  handleClickSortByName(e: Event) {
    e.preventDefault()
    const { sortControls } = this.state

    if (this.state.sortBy === 'name') {
      this.setState({
        sortControls: {
          ...sortControls,
          name: {
            ...sortControls.name,
            direction: sortControls.name.direction == 'asc' ? 'desc' : 'asc'
          }
        }
      })
    }
    else {
      this.setState({
        sortBy: 'name'
      })
    }

    this.delayedForceReload()
  }

  handleClickPage(e: Event, pageNumber: number) {
    e.preventDefault()

    this.setCurrentPage(pageNumber)
  }

  handleClickNext(e: Event) {
    e.preventDefault()

    this.setCurrentPage(this.state.currentPage + 1)
  }

  handleClickPrevious(e: Event) {
    e.preventDefault()

    this.setCurrentPage(this.state.currentPage - 1)
  }

  handleExtendedFilterItemClick(e: Event, lsCat: LearningSphereCategory) {
    const index = this.state.activeExtendedFilters.indexOf(lsCat.ID)

    if (index === -1) {
      this.setState({
        activeExtendedFilters: [ ...this.state.activeExtendedFilters, lsCat.ID ],
        currentPage: 0
      })
    }
    else if (index !== -1) {
      this.setState({
        activeExtendedFilters: this.state.activeExtendedFilters.filter(i => i !== lsCat.ID),
        currentPage: 0
      })
    }

    this.forceReload()
  }

  handleExtendedFilterClick(e) {
    e.preventDefault()

    this.setState({
      extendedFilterOpen: !this.state.extendedFilterOpen,
      currentPage: 0
    })
  }

  handleTimeFilterChange(e: Event) {
    const element = e.target as HTMLInputElement

    this.setState({
      currentPage: 0,
      activeTimeFilter: element.value as 'all'|'past'|'current'|'future',
    })

    this.forceReload()
  }

  setCurrentPage(pageNumber: number) {
    if (pageNumber < 1) {
      pageNumber = 1
    }
    this.setState({
      currentPage: pageNumber
    })
  }


  listSort(list: Category[]) {
    switch (this.state.sortBy) {
      case 'name': return list.sort(this.sortByName)
      default:     return list.sort(this.sortByName)
    }
  }

  sortByName(a: Category, b: Category) {
      const nameA = a.Name.toLowerCase()
      const nameB = b.Name.toLowerCase()

      const isNewA = a.IsNew
      const isNewB = b.IsNew

      if (isNewA > isNewB)      { return -1; }
      else if (isNewA < isNewB) { return 1; }
      else {
        if (this.state.sortControls.name.direction == 'desc') {
          if (nameA < nameB) { return -1; }
          else if (nameA > nameB) { return 1; }
        }
        else {
          if (nameA < nameB) { return 1; }
          else if (nameA > nameB) { return -1; }
        }

        return 0;
      }
  }

  filterList(list: Category[]) {
    const { filterQuery, activeExtendedFilters, activeTimeFilter } = this.state

    if (filterQuery) {
      list = list.filter(category => category.Name.toLowerCase().includes(filterQuery))
    }

    if (activeExtendedFilters.length) {
      list = list.filter((category: Category) => intersection(activeExtendedFilters, category.Categories.map(i => parseInt(i, 10))).length)
    }

    switch(activeTimeFilter) {

      case 'past': list = list.filter((i: Category) => {
        return i.EndDate && parseInt(i.EndDate, 10) * 1000 < this.now
      }); break

      case 'current': list = list.filter((i: Category) => {
        return ((!i.StartDate || parseInt(i.StartDate, 10) * 1000 <= this.now) &&
                (!i.EndDate || parseInt(i.EndDate, 10) * 1000 > this.now)) ||
                i.StartDate && parseInt(i.StartDate, 10) * 1000 > this.now
      }); break

      case 'future': list = list.filter((i: Category) => {
        return i.StartDate && parseInt(i.StartDate, 10) * 1000 > this.now
      }); break
    }

    return list
  }

  getHumanDateFromTimestamp(timestamp) {
    const date = new Date(timestamp)
    return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`
  }

  handleItemActionFocus(e: Event) {
    const tileElem = (e.target as HTMLElement).closest('.learning-catalog-item') as HTMLElement
    tileElem.classList.add('is-focused')
  }

  handleItemActionFocusOut(e: Event) {
    const tileElem = (e.target as HTMLElement).closest('.learning-catalog-item') as HTMLElement
    tileElem.classList.remove('is-focused')
  }

  handleItemAction(e: Event, category: Category, actionType: string) {
    switch(actionType) {
      case 'learning-sphere-info': return this.handleItemActionInfo(e, category)
    }
  }

  handleItemActionInfo(e: Event, category: Category) {
    const elem = e.target as HTMLAnchorElement
    const hash = elem ? elem.hash.substring(1) : null

    // If there is a hash in the link it should be handled by the router
    if (!hash) {
      this.renderInfoModal(category)
      e.preventDefault()
    }
  }

  renderInfoModal(category: Category) {

    const { Name, Description, IsApplied, IsEnrolled, InfoActions } = category

    const content = [
      <h1 className="learning-sphere__heading">{Name}</h1>
    ]

    // Special labels
    const specialLabels: { name: string, title: string, icon?: string }[] = []

    if (category.IsClosedGroup) {
      specialLabels.push({ name: 'is-closed-group', title: 'Geschlossener Lernraum', icon: 'lock' })
    }

    if (category.IsAutodidact) {
      specialLabels.push({ name: 'is-autodidact', title: 'Selbstlernkurs', icon: 'user' })
    }

    if (specialLabels.length) {
      content.push(
        <div class="learning-sphere__block learning-sphere__labels">
          { specialLabels.map((label) => (
            <div class={`learning-sphere__label learning-sphere__label--${label.name}`}>
              { !label.icon ? null :
                <i className={`icon fa fa-${label.icon}`}></i>
              }
              {label.title}
            </div>
          ))
          }
            </div>
      )
    }

    // Manager list
    // Category keys to langstrings mapping
    const managerKeys = [
      'Localmanagers',
      'CoLocalmanagers',
      'CoTeachers'
    ]

    const localmanagersNodes: JSX.Element[] = []

    managerKeys.forEach(managerKey => {
      if (Object.keys(category[managerKey].Items).length) {
        localmanagersNodes.push(
          <div className="manager-block">
            <p><strong>{category[managerKey].Label}:</strong></p>
            <ul className="manager-list">
            {
              Object.keys(category[managerKey].Items).map(id => {
                let user: User = category[managerKey].Items[id]
                return (
                  <li>
                    <a href={`/user/profile.php?id=${id}`}>
                      <img className="userpicture" src={`${user.picture_url}`} />
                      {user.firstname} {user.lastname}
                    </a>
                  </li>
                )
              })
            }
            </ul>
          </div>
        )
      }
    })

    if (localmanagersNodes.length) {
      content.push(
        <div class="learning-sphere__block learning-sphere__managers">{ localmanagersNodes }</div>
      )
    }

    // Info actions

    if (InfoActions && InfoActions.length) {

      const actions = InfoActions.map((infoAction: Action) => (
        this.renderActionButton(category, { Name: infoAction.Name, Link: infoAction.Link, Type: infoAction.Type })
      ))

      content.push(
        <div className="learning-sphere__block learning-sphere__actions">
          { actions }
          </div>,
      )
    }


    // Description

    if (Description) {
      content.push(
        <div className="learning-sphere__block learning-sphere__description" dangerouslySetInnerHTML={{ __html: Description}}></div>
      )
    }

    renderModal({
      title: `Informationen zum Lernraum`,
      content,
      options: {
        type: 'learning-sphere-info',
        beforeClose: () => {
          const hash = this.locationHash

          // Remote hash from url, so modal won't be reopened on reload
          if (hash) {
            if (this.learningSphereHashRegExp.test(hash)) {
              history.pushState("", document.title, window.location.pathname + window.location.search);
            }
          }
        }
      }
    })
  }

  render() {
    const { currentPage, isLoading } = this.state

    const { mode = 'default' } = this.props

    return (
      <div className="learning-catalog__content" data-mode={mode}>
        { this.modesWithToolbar.includes(mode) ? this.renderToolbar() : null }
        { this.renderList() }

        <footer className="learning-catalog__footer">
          { this.renderReloadHandler() }
          { this.renderReloadToken() }
          { isLoading ? this.renderLoader() : null }
        </footer>
      </div>
    )
  }

  handleClickReloadHandler(e) {
    e.preventDefault()

    this.requestAutoReload(true)
  }

  renderReloadHandler() {
    const { isLoading, endOfData } = this.state

    if (endOfData || isLoading || !this.isManualReloadPage()) {
      return
    }

    return (
      <a href="#refresh" className="btn" onClick={this.handleClickReloadHandler}>
        Mehr anzeigen <i className="fa fa-angle-down"></i>
      </a>
    )
  }

  renderReloadToken() {
    return (
      <div ref={(elem: HTMLDivElement) => this.reloadToken = elem} className="learning-catalog__reload-token"></div>
    )
  }

  renderLoader() {
    return (
      <div className="loader">
        <LdsLoader />
        <p>Daten werden geladen...</p>
      </div>
    )
  }

  renderToolbar() {

    const { mode = 'default' } = this.props
    const { name: sortNameControl } = this.state.sortControls

    return (
      <div className="learning-catalog__toolbar">
        <form className="learning-catalog__search-form form-inline form-group" onSubmit={linkEvent(this, this.handleFilterSubmit)}>
          <input
            class="form-control"
            name="search"
            type="search"
            placeholder="Hier Suchbegriff eingeben"
            onInput={linkEvent(this, this.handleFilterQuery)}
          />

          { this.modesWithFilter.includes(mode) ? this.renderTimeFilter() : null }
        </form>

        <div class="sort-controls">

          <a data-direction={sortNameControl.direction} class="sortby-name btn btn-link" href="#sortbyname" onClick={(e: Event) => this.handleClickSortByName(e)}>
            {sortNameControl.label}
            {
              sortNameControl.direction === 'asc' ?
                <i className="fa fa-arrow-up"></i> : <i className="fa fa-arrow-down"></i>
            }
          </a>

        </div>

        { this.modesWithExtendedFilter.includes(mode) ?  this.renderExtendedFilter() : null }

      </div>
    )
  }

  renderActionButton(category: Category, action: Action) {
    const props: any = {
      'data-actiontype': action.Type,
      href: action.Link,
      tabindex: 0,
      onFocus: (e: Event) => this.handleItemActionFocus(e),
      onFocusOut: (e: Event) => this.handleItemActionFocusOut(e),
      onClick: (e: Event) => this.handleItemAction(e, category, action.Type),
        className: classNames(
          'learning-catalog-item__action',
          'btn btn-primary',
          {
            'btn-goto': action.Link
          }
      )
    }

    switch (action.Type) {
      case 'learning-sphere-info': {
        props.href = `#${this.props.mode}-lernraum-${category.ID}`
      } break;

      case 'show-learning-sphere': {
        props.onClick = (e: Event) => this.handleClickShow(category, e)
      } break;
    }

    return (
      <a {...props}>{action.Name}</a>
    )
  }

  renderList() {
    const {
      categories, courses,
      isLoading, itemsPerPage, currentPage,
      filterQuery, activeExtendedFilters
    } = this.state

    const hasItems: number|null = (categories && categories.length) || (courses && courses.length)

    if (!categories) {
      return null
    }

    //let filteredCategories: Category[] = categories

    //if (categories && categories.length) {
      //filteredCategories = this.filterList(categories)
    //}

    //filteredCategories = this.listSort(filteredCategories)

    if (!hasItems && !isLoading) {
      return (
        <p className="alert alert-info">Keine Lernräume gefunden.</p>
      )
    }
    else if (hasItems) {
      return (
        <div className="learning-catalog__list">
          <ul className="learning-catalog__listitem">
            {
              !categories ?
                null : categories.map((category: Category) => this.renderCategoryItem(category))
            }
          </ul>
        </div>
      )
    }
  }

  renderCategoryItem(category: Category) {

    const imageStyles:any = {}

    if (category.ImageUrl) {
      imageStyles.backgroundImage = `url("${category.ImageUrl}")`
    }

    var className = classNames(
      'learning-catalog-item',
      'learning-catalog-item--category',
      {
        'learning-catalog-item--has-image': !!category.ImageUrl,
        'learning-catalog-item--is-invisible': !category.IsVisible
      }
    )

    const endDate: number|null = category.EndDate ? parseInt(category.EndDate, 10) * 1000 : null
    const startDate: number|null = category.StartDate ? parseInt(category.StartDate, 10) * 1000 : null

    const endsSoon: boolean = !endDate ? false : (endDate - this.now - this.endDateThreshold) <= 0 && endDate > this.now
    const hasEnded: boolean = !endDate ? false : endDate < this.now
    const startsInFuture: boolean = !startDate ? false : startDate > this.now

    const lastVisited: number|null = category.LastVisited ? parseInt(category.LastVisited, 10) * 1000 : null

    return (
      <li key={`category-${category.ID}`} className={className} data-id={category.ID}>
        { !category.IsNew ? null :
          <div className="learning-catalog-item__is-new">
            <span className="text">Neu</span>
          </div>
        }
        <div className="learning-catalog-item__labels">
          { !category.IsApplied || category.IsEnrolled ? null :
            <div className="learning-catalog-item__label learning-catalog-item__label--is-applied">
              Beantragt
            </div>
          }
          { !category.IsEnrolled ? null :
            <div className="learning-catalog-item__label learning-catalog-item__label--is-enrolled">
              Eingeschrieben
            </div>
          }
        </div>
        <div className="learning-catalog-item__image-container">
        {
          <div className="learning-catalog-item__image" style={imageStyles}></div>
        }
        </div>
        <div className="learning-catalog-item__bottom">
          { !lastVisited ? null :
            <span className="learning-catalog-item__label learning-catalog-item__label--lastvisited">Zuletzt besucht: {this.getHumanDateFromTimestamp(lastVisited)}</span>
          }
          { !endsSoon ? null :
            <span className="learning-catalog-item__label learning-catalog-item__label--enddate">Endet bald: {this.getHumanDateFromTimestamp(endDate)}</span>
          }
          { !hasEnded ? null :
            <span className="learning-catalog-item__label learning-catalog-item__label--enddate">Abgelaufen am {this.getHumanDateFromTimestamp(endDate)}</span>
          }
          { !startsInFuture ? null :
            <span className="learning-catalog-item__label learning-catalog-item__label--future">Startet am {this.getHumanDateFromTimestamp(startDate)}</span>
          }
          <h4 className="learning-catalog-item__title">{category.Name}</h4>
        </div>
        <div className="learning-catalog-item__action-list">
          {
            category.Actions.map(action => this.renderActionButton(category, action))
          }
        </div>
      </li>
    )
  }

  renderTimeFilter() {

    const { mode = 'default' } = this.props

    const { activeTimeFilter } = this.state

    const filters = [
      { value: 'all',     name: M.str.theme_project.all_learningspheres },
      { value: 'past',    name: M.str.theme_project.past_learningspheres },
      { value: 'current', name: M.str.theme_project.latest_learningspheres },
      { value: 'future',  name: M.str.theme_project.future_learningspheres },
    ]

    return (
      <select class="form-control" id="time-filter" name="time-filter" onchange={e => this.handleTimeFilterChange(e)}>
        {
          filters.map((item: { value: string, name: string }) => (
            <option value={ item.value } selected={item.value === activeTimeFilter }>
              { item.name }
            </option>
          ))
        }
      </select>
    )
  }

  renderExtendedFilter() {

    const { mode = 'default' } = this.props

    const {
      extendedFilterOpen,
      activeExtendedFilters,
      learningSphereCategories
    } = this.state

    if (!learningSphereCategories.length) {
      return null
    }

    const containerClasses = classNames(
      'extended-filter', {
        'extended-filter--open': extendedFilterOpen,
        'extended-filter--active': activeExtendedFilters.length >= 1
      })

    const contentClasses = classNames(
      'extended-filter__content', {
        'extended-filter__content--horizontal': learningSphereCategories.length < 20
      })

    return (
      <div className={containerClasses}>
        <a className="title" onClick={e => this.handleExtendedFilterClick(e)} href="#"><i className="fa fa-filter"></i>{M.str.theme_project.advanced_filter}</a>

        { !extendedFilterOpen ? null :
          <div className={contentClasses}>
            {
              learningSphereCategories.map(( lsCat: LearningSphereCategory, index: number) => {
                const onClickHandler = (e: Event) => this.handleExtendedFilterItemClick(e, lsCat)
                const labelProps = {
                  for: `filter_${index}`
                }
                const checkboxProps = {
                  type: 'checkbox',
                  onClick: onClickHandler,
                  checked: activeExtendedFilters.includes(lsCat.ID),
                  id: `filter_${index}`
                }

                return (
                  <div className="form-check">
                    <input className="form-check-input" {...checkboxProps} />
                    <label className="form-check-label" {...labelProps}>{lsCat.Name}</label>
                  </div>
                )
              }
              )
            }
          </div>
        }
      </div>
    )
  }
}

export default LearningCatalog
